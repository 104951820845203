export const ROLES = ['SUPER_ADMIN', 'ADMIN', 'ORGANIZATION_ADMIN', 'NATIONAL_USER', 'STATE'];

export const QUESTION_VALUE_TYPE = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
};

export const QUESTION_FILTER_TYPE = {
  NONE: 'NONE',
  LOCATION: 'LOCATION',
  ATTRIBUTE: 'ATTRIBUTE',
  QUESTION: 'QUESTION',
};
