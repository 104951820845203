/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faFileAlt, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionContext,
  Button,
  Col,
  Container,
  ListGroup,
  Row,
  Spinner,
  Tab,
  Tabs,
  useAccordionToggle,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getSections } from '../../../../actions/section.action';
import { getSurveyQuestionsBySurveyIdAndSection } from '../../../../actions/survey.action';
import { getSurveysSummary } from '../../../../actions/survey.summary.action';
import { getTopics } from '../../../../actions/topic.action';
import {
  getSectionCodeByNameAndSurveyData,
  removeDuplicatedSections,
  sortSections,
  removeEmptySections,
} from './sectionUtils';
import './styles.css';

const AccordionToggle = ({ eventKey, prompt }) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey);

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      className="cursor-pointer d-flex align-items-center"
      role="button"
      onClick={decoratedOnClick}
    >
      <h3 className="mt-3 mb-3 mr-auto font-weight-lighter">
        <span>{prompt}</span>
      </h3>
      <FontAwesomeIcon
        size="lg"
        className={clsx(['ml-1 mr-5', { 'rotate-accordion-toggle': !isCurrentEventKey }])}
        icon={isCurrentEventKey ? faSortDown : faSortUp}
      />
    </div>
  );
};

AccordionToggle.propTypes = {
  eventKey: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
};

const TopicsTab = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [tabSelected, setTabSelected] = useState('survey-questions');
  const [step, setStep] = useState(1);
  const [selectedTopic, setSelectedTopic] = useState();
  const [selectedSection, setSelectedSection] = useState();
  const [selectedSurvey, setSelectedSurvey] = useState();
  const storeJwt = useSelector((state) => state.session.jwt);
  const { isLoading, allTopics } = useSelector((state) => state.topics);
  const { surveys } = useSelector((state) => state.surveySummary);
  const { allSections } = useSelector((state) => state.sections);
  const { surveyQuestions, isLoadingSurveyQuestions } = useSelector((state) => state.surveys);
  const [filteredSections, setFilteredSections] = useState([]);

  const handleSelectTopic = (topic) => {
    setFilteredSections([]);
    setSelectedTopic(topic);
    setSelectedSurvey();
    setStep(2);
  };

  const handleSelectSurvey = (survey, section) => {
    setSelectedSurvey(survey);
    setSelectedSection(section);
    const sectionCode = getSectionCodeByNameAndSurveyData(section.Data.name, survey);

    dispatch(getSurveyQuestionsBySurveyIdAndSection(survey.id, sectionCode, storeJwt));
  };

  const handleViewSelectedSurvey = () => {
    localStorage.setItem('sectionId', selectedSection.Data.code);
    localStorage.removeItem('questionId');
    history.push(`/surveys/${selectedSurvey.id}`);
  };

  const onViewSurveysClick = () => {
    history.push('/survey-explorer');
  };

  const handleQuestionClick = (questionId) => {
    localStorage.setItem('sectionId', selectedSection.Data.code);
    localStorage.setItem('questionId', questionId);
    history.push(`/surveys/${selectedSurvey.id}`);
  };

  useEffect(() => {
    let filtSect = [];
    if (selectedTopic && allSections && surveys) {
      const topicSections = [];
      topicSections.push(
        ...allSections.filter((section) => selectedTopic.Data.sections.includes(section.Id))
      );
      filtSect = removeDuplicatedSections(topicSections);
      filtSect = removeEmptySections(filtSect, surveys);
      filtSect = sortSections(filtSect);
    }
    setFilteredSections([...filtSect]);
  }, [selectedTopic, allSections, surveys]);

  useEffect(() => {
    if (surveyQuestions.questions && !isLoadingSurveyQuestions) {
      setStep(3);
    }
  }, [surveyQuestions, isLoadingSurveyQuestions]);

  useEffect(() => {
    dispatch(getSurveysSummary(storeJwt, { publicOnly: true }));
    dispatch(getTopics(storeJwt));
    dispatch(getSections(storeJwt));
    setStep(1);
  }, [dispatch, storeJwt]);

  if (isLoading) {
    return (
      <Container fluid>
        <Row>
          <Col className="d-flex min-vh-100 justify-content-center align-items-center">
            <Spinner animation="border" />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="topics-container bg-blue-400 mt-4" fluid>
      <Row className="mt-3 w-100">
        <Col sm="4" md="4" lg="4" className="d-flex align-items-center justify-content-center">
          <span
            className={clsx([
              'text-xl bg-white rounded-circle step-circle',
              { 'current-step': step === 1 },
              { 'step-done': step > 1 },
            ])}
          >
            1
          </span>
        </Col>
        <Col sm="4" md="4" lg="4" className="d-flex align-items-center justify-content-center">
          <span
            className={clsx([
              'text-xl bg-white rounded-circle step-circle',
              { 'current-step': step === 2 },
              { 'step-done': step > 2 },
            ])}
          >
            2
          </span>
        </Col>
        <Col sm="4" md="4" lg="4" className="d-flex align-items-center justify-content-center">
          <span
            className={clsx([
              'text-xl bg-white rounded-circle step-circle',
              { 'current-step': step === 3 },
            ])}
          >
            3
          </span>
        </Col>
      </Row>
      <Row className="mt-5 topic-steps">
        <Col sm="4" md="4" lg="4">
          <Container fluid className="p-4 bg-white topic-step">
            <h3>Topics</h3>
            <ListGroup className="mt-4">
              {allTopics
                .sort((a, b) => a.Data.name.localeCompare(b.Data.name))
                .map((t) => (
                  <ListGroup.Item
                    className={clsx([
                      'pl-1 border-0',
                      { 'selected-topic': selectedTopic?.Id === t.Id },
                    ])}
                    action
                    key={t.Id}
                    onClick={() => handleSelectTopic(t)}
                  >
                    {t.Data.name}
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Container>
        </Col>
        <Col sm="4" md="4" lg="4">
          <Container
            fluid
            className={clsx([
              'p-4 bg-white topic-step',
              { 'justify-content-between': !selectedTopic },
            ])}
          >
            <h3>Survey sections in this topic</h3>
            {!selectedTopic && (
              <p>Select a Topic in the first column to see matching survey sections.</p>
            )}
            {selectedTopic && (
              <ListGroup className="mt-4">
                {filteredSections.map((section) => {
                  const filteredSurveys = surveys.filter((survey) =>
                    survey.surveySummaryDetailsDto?.sections
                      .trim()
                      .toLowerCase()
                      .split(', ')
                      .includes(section.Data.name.trim().toLowerCase())
                  );

                  return (
                    <Accordion className="w-100" key={section.sectionKeyId || section.Id}>
                      <div
                        className="w-100 text-left border-0 py-2 d-flex"
                        id={section.sectionKeyId || section.Id}
                      >
                        <div className="w-100">
                          <AccordionToggle
                            eventKey={section.sectionKeyId || section.Id}
                            prompt={section.Data.name}
                          />
                        </div>
                      </div>
                      <Accordion.Collapse eventKey={section.sectionKeyId || section.Id}>
                        <>
                          {filteredSurveys.length === 0 && <p>No surveys for this section</p>}
                          {filteredSurveys.map((survey) => (
                            <ListGroup.Item
                              className="pl-1 py-2 border-0"
                              action
                              key={survey.id}
                              onClick={() => handleSelectSurvey(survey, section)}
                            >
                              <Container
                                fluid
                                className={clsx([
                                  'survey-section-item',
                                  {
                                    'selected-section-survey':
                                      selectedSurvey?.id === survey.id &&
                                      selectedSection?.Id === section.Id,
                                  },
                                ])}
                              >
                                <Row>
                                  <Col md={1}>
                                    <FontAwesomeIcon icon={faFileAlt} />
                                  </Col>
                                  <Col>
                                    <h3 className="my-1">{survey.title}</h3>
                                    <p className="mb-0">{survey.typeFullName}</p>
                                  </Col>
                                </Row>
                              </Container>
                            </ListGroup.Item>
                          ))}
                        </>
                      </Accordion.Collapse>
                    </Accordion>
                  );
                })}
              </ListGroup>
            )}
            {!selectedTopic && <span />}
          </Container>
        </Col>
        <Col sm="4" md="4" lg="4">
          <Container fluid className="p-4 bg-white topic-step justify-content-between">
            {!selectedSurvey && (
              <>
                <h3>Survey info</h3>
                <p>
                  Select a survey to see a list of questions in the matching survey section, along
                  with more information
                </p>
              </>
            )}
            {selectedSurvey && (
              <Container>
                <Container fluid className="p-0 survey-summary-details">
                  <h3>{selectedSection.Data.name}</h3>
                  <h4 className="font-weight-lighter mt-2 mb-1">in {selectedSurvey.title}</h4>
                </Container>
                <Tabs
                  id="survey-info-tabs"
                  activeKey={tabSelected}
                  onSelect={(k) => setTabSelected(k)}
                >
                  <Tab
                    eventKey="survey-questions"
                    title={<div className="tab-title-with-icon">Survey Questions</div>}
                  >
                    <div className="survey-questions py-3">
                      {surveyQuestions.questions?.map((q) => (
                        <p
                          className="cursor-pointer hover-column"
                          key={q.id}
                          onClick={() => handleQuestionClick(q.id)}
                        >
                          {q.displayIdentifier} - {q.prompt}
                        </p>
                      ))}
                    </div>
                  </Tab>
                  <Tab
                    eventKey="more-info"
                    title={<div className="tab-title-with-icon">More Info</div>}
                  >
                    <div className="survey-questions py-3">
                      <Container fluid className="p-0 mt-3 survey-summary-details">
                        <h4 className="mb-2">Section Names</h4>
                        <p>{selectedSurvey.surveySummaryDetailsDto?.sections}</p>
                      </Container>
                      <Container fluid className="p-0 survey-summary-details">
                        <h4 className="mb-2">State oversample</h4>
                        <p>{selectedSurvey.surveySummaryDetailsDto?.oversampledStates}</p>
                      </Container>
                      <Container fluid className="p-0 survey-summary-details">
                        <h4 className="mb-2">Administered</h4>
                        <p>{selectedSurvey.surveySummaryDetailsDto?.dateAdministrated}</p>
                      </Container>
                    </div>
                  </Tab>
                </Tabs>
              </Container>
            )}
            <Button
              disabled={!selectedSurvey}
              className="btn-view-survey"
              onClick={handleViewSelectedSurvey}
            >
              View Survey Section
            </Button>
          </Container>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Button className="btn-view-survey w-100" onClick={onViewSurveysClick}>
            Go to Survey Explorer
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default TopicsTab;
