/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSurveySampleById,
  getSurveySampleDimensionsById,
} from '../../../../actions/survey.wizard.action';
import themes from '../../../../constants/themes';
import { QUESTION_VALUE_TYPE } from '../../../../constants/common';

const DEFAULT_SINGLE_CHART_SETTINGS = {
  chart: {
    type: 'bar',
    marginTop: 90,
    paddingTop: 0,
    height: 400,
    backgroundColor: null,
    marginLeft: 300,
    spacingLeft: 60,
    style: {
      fontFamily: 'AvenirNext-Regular',
    },
    plotBorderColor: '#f7f7f7',
    events: {},
  },
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  navigation: {
    activeColor: '#ac193c',
    buttonOptions: {
      enabled: false,
    },
  },
  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
    },
  },
  xAxis: {
    categories: [],
    gridLineColor: '#b8b4b4',
    labels: {
      style: {
        color: '#121111',
        fontSize: '14px',
        textAlign: 'right',
        whiteSpace: 'wrap',
        textOverflow: 'none',
      },
      align: 'right',
      x: -10,
      y: null,
    },
    lineColor: '#b8b4b4',
    minorGridLineColor: '#b8b4b4',
    tickColor: '#f7f7f7',
    title: {
      style: {
        color: '#121111',
      },
    },
  },
  yAxis: {
    reversedStacks: false,
    min: 0,
    gridLineColor: '#b8b4b4',
    labels: {
      enabled: true,
      style: {
        color: '#bdbdbd',
        fontSize: '14px',
      },
    },
    lineColor: '#b8b4b4',
    minorGridLineColor: '#b8b4b4',
    tickColor: '#f7f7f7',
    tickWidth: 1,
    tickInterval: 20,
    endOnTick: false,
    title: {
      text: '',
      style: {
        color: '#121111',
      },
    },
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: true,
        padding: 0,
        rotation: 0,
        shadow: false,
        style: {
          textOutline: false,
          fontSize: '14px',
        },
        shape: 'square',
        useHTML: false,
      },
      marker: {
        lineColor: '#b8b4b4',
      },
    },
    bar: {
      stacking: 'normal',
      events: {},
    },
  },
  legend: {
    enabled: true,
    layout: 'horizontal',
    x: 0,
    y: -10,
    margin: 0,
    padding: 0,
    symbolRadius: 0,
    itemStyle: {
      fontSize: '14px',
      color: '#606060',
      textOverflow: 'wrap',
    },
    paddingRight: 20,
    maxHeight: 120,
    width: '100%',
    navigation: {
      activeColor: '#ac193c',
    },
    itemHoverStyle: {
      color: '#606060',
    },
    itemHiddenStyle: {
      color: '#606060',
    },
    align: 'center',
    verticalAlign: 'top',
  },
  series: [
    {
      name: 'No',
      filtered: false,
      segmented: false,
      color: '#f3586d',
      MOE: [null],
      benchmark_array: [35],
      benchmark_labels: ['the US'],
      data: [35],
      cat: 'negative',
      option_type: 'RANGEPOSITIVE',
    },
    {
      name: 'Yes',
      filtered: false,
      segmented: false,
      color: '#426E99',
      MOE: [null],
      benchmark_array: [65],
      benchmark_labels: ['the US'],
      data: [65],
      cat: 'positive',
      option_type: 'RANGEPOSITIVE',
    },
  ],
};

const SurveyQuestion = ({ question, onSelectedQuestion, isSelected }) => {
  const dispatch = useDispatch();
  const storeJwt = useSelector((state) => state.session.jwt);
  const { sample, dimensions } = useSelector((state) => state.surveyWizard);
  const [chartConfig, setChartConfig] = useState();
  const handleQuestionClick = () => {
    if (isSelected) return;
    onSelectedQuestion(question.id);
    dispatch(getSurveySampleById(question.id, storeJwt));
    dispatch(getSurveySampleDimensionsById(question.id, storeJwt));
  };

  const getSimpleQuestionConfig = useCallback(() => {
    const conf = { ...DEFAULT_SINGLE_CHART_SETTINGS };
    conf.xAxis.categories.push(sample.Data.label);
    if (dimensions?.length > 0) {
      let sum = 0;
      const theme = sample.Data.theme || themes[0];
      conf.series = Object.keys(dimensions[0].Data.allowedValues)
        .filter((key) => key >= 0)
        .map((key, index) => {
          const randomNumber = Math.floor(Math.random() * 50) + 1;
          let numberForOption = 0;
          sum += randomNumber;
          if (sum < 100) {
            numberForOption = randomNumber;
          } else {
            sum -= randomNumber;
            numberForOption = 100 - sum;
            sum += numberForOption;
          }
          return {
            name: dimensions[0].Data.allowedValues[key],
            filtered: false,
            segmented: false,
            color: theme.basic[index],
            MOE: [null],
            benchmark_array: [numberForOption],
            benchmark_labels: ['the US'],
            data: [numberForOption],
            cat: 'positive',
            option_type: 'RANGEPOSITIVE',
          };
        });
    }

    setChartConfig({ ...conf });
  }, [dimensions, sample, setChartConfig]);

  const getSimpleMultipleConfig = useCallback(() => {
    const conf = {
      chart: {
        type: 'bar',
        marginLeft: 200,
        marginTop: 50,
        marginRight: 50,
        events: {
          load() {},
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            enabled: false,
          },
        },
      },
      title: null,
      credits: { enabled: false },
      plotOptions: {
        series: {
          groupPadding: 0.05,
          pointPadding: 0.007,
          dataLabels: {
            x: 0,
            enabled: true,
            format: ['OPEN_NUMERIC', 'OPEN_TEXT'].includes(sample.Data.questionType)
              ? '{y}'
              : '{y}%',
          },
        },
      },
      series: [
        {
          data: [],
          name: 'All respondents',
          color: '#3C6384',
        },
      ],
      // xAxis formatting will be static
      xAxis: {
        categories: [],
        labels: {
          style: {
            color: '#121111',
            fontSize: '14px',
          },
        },
      },
      yAxis: {
        visible: false,
      },
      legend: {
        layout: 'horizontal',
        symbolRadius: 0,
        align: 'center',
        verticalAlign: 'top',
      },
    };

    conf.xAxis.categories.push(sample.Data.label);
    if (dimensions?.length > 0) {
      conf.xAxis.categories = dimensions.map((dimension) => dimension.Data.label);
      conf.series[0].data = dimensions.map(() => Math.floor(Math.random() * 50) + 1);
    }

    setChartConfig({ ...conf });
  }, [dimensions, sample, setChartConfig]);

  useEffect(() => {
    if (!sample) {
      setChartConfig(null);
      return;
    }
    if (!sample) return;
    if (sample.Data.valueType === QUESTION_VALUE_TYPE.SINGLE) {
      getSimpleQuestionConfig();
    } else if (sample.Data.valueType === QUESTION_VALUE_TYPE.MULTIPLE) {
      getSimpleMultipleConfig();
    }
  }, [sample, getSimpleQuestionConfig, getSimpleMultipleConfig]);

  return (
    <div className="d-flex flex-column">
      <div
        className="w-100 text-left border-0 bg-gray-200 py-2 d-flex mt-5"
        id={question?.id}
        key={question?.id}
      >
        <div className="w-100" onClick={handleQuestionClick} role="button" tabIndex={0}>
          <div className="cursor-pointer d-flex align-items-center mr-5">
            <h3 className="mt-3 mb-3 ml-5 mr-auto">
              <span className="mr-5">{question?.displayIdentifier}</span>
              <span>{question?.prompt}</span>
            </h3>
          </div>
        </div>
      </div>
      {isSelected && chartConfig && sample && (
        <Row noGutters className="bg-white mt-3">
          <Col md="10" className="pl-3 pt-3">
            {sample?.Data.valueType !== QUESTION_VALUE_TYPE.MULTIPLE && (
              <HighchartsReact highcharts={Highcharts} options={chartConfig} />
            )}
            {sample?.Data.valueType === QUESTION_VALUE_TYPE.MULTIPLE && (
              <HighchartsReact highcharts={Highcharts} options={chartConfig} />
            )}
          </Col>
          <Col md="2" className="p-2 d-flex flex-column">
            <div>
              <h4>Active Filter Type</h4>
              <Badge pill variant="dark">
                {sample.Data.filterType}
              </Badge>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SurveyQuestion;
