/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { Button, Card, CardGroup, Col, Container, Modal, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import './style.css';

const HomePage = () => {
  const history = useHistory();
  const [showTopicVideoModal, setShowTopicVideoModal] = useState(false);
  const style = {
    header: {
      backgroundImage: "url('/explorer-rectangle-bg.svg')",
    },
  };

  // useEffect(() => {
  //   const TOPIC_SEARCH_VIDEO_VIEWED = Boolean(localStorage.getItem('TOPIC_SEARCH_VIDEO_VIEWED'));
  //   if (!TOPIC_SEARCH_VIDEO_VIEWED) {
  //     setShowTopicVideoModal(true);
  //   }
  // }, []);

  const handleCloseTopicModal = () => {
    localStorage.setItem('TOPIC_SEARCH_VIDEO_VIEWED', true);
    setShowTopicVideoModal(false);
  };

  const handleGoToTopicSearch = () => {
    localStorage.setItem('TOPIC_SEARCH_VIDEO_VIEWED', true);
    setShowTopicVideoModal(false);
    history.push('/survey-topics');
  };

  return (
    <Container fluid className="p-0">
      <div className="main-cards-dash-survey-bg">{'    '}</div>
      <Container>
        <Row justify="space-between" align="center" className="main-cards-dash-survey-row">
          <Col md="6">
            <Card className="main-cards-dash-survey">
              <Card.Img
                variant="top"
                src="/file_searching.svg"
                className="main-cards-dash-survey-img"
              />
              <Card.Body className="main-cards-dash-survey-body">
                <Card.Title className="main-cards-dash-survey-title">Survey Explorer</Card.Title>
                <Card.Text className="main-cards-dash-survey-text">
                  Explore national education surveys from teachers, principals, superintendents, and
                  students.
                </Card.Text>
                <Button href="/survey-topics" className="card-reports-button">
                  Search by topic
                </Button>
                <Button href="/survey-explorer" className="card-reports-button ml-2">
                  See all surveys
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card className="main-cards-dash-survey">
              <Card.Img
                variant="top"
                src="/data_trends.svg"
                className="main-cards-dash-survey-img"
              />
              <Card.Body className="main-cards-dash-survey-body">
                <Card.Title className="main-cards-dash-survey-title">Dashboard</Card.Title>
                <Card.Text className="main-cards-dash-survey-text">
                  Review education data, demographics information, and survey data for states and
                  districts.
                </Card.Text>
                <Button href="/dashboard" className="card-reports-button">
                  &nbsp;&nbsp;&nbsp;Go to Dashboard&nbsp;&nbsp;&nbsp;
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row align="center">
          <Col md="12" className="align-items-center card-expl-edu-main-title h2">
            Explore education data and build custom, shareable visualizations
          </Col>
        </Row>
        <CardGroup>
          <Card className="card-explore-education-data">
            <div className="card-expl-edu-main-header" style={style.header}>
              <Card.Title className="card-expl-edu-title">Filter &amp; Segments</Card.Title>
              {/* TODO: change this current image for the correct gif from new dashboard, filter & sections part */}
              <Card.Img variant="top" src="/filter-segment.gif" className="card-expl-edu-img" />
            </div>
            <Card.Body>
              <Card.Text className="card-expl-edu-text">
                Filter responses based on location, school characteristics, or educator background.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="card-explore-education-data">
            <div className="card-expl-edu-main-header" style={style.header}>
              <Card.Title className="card-expl-edu-title">Benchmark</Card.Title>
              {/* TODO: change this current image for the correct gif from new dashboard, benchmark part */}
              <Card.Img variant="top" src="/benchmark.gif" className="card-expl-edu-img" />
            </div>
            <Card.Body>
              <Card.Text className="card-expl-edu-text">
                Compare local data to national and state averages.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="card-explore-education-data">
            <div className="card-expl-edu-main-header" style={style.header}>
              <Card.Title className="card-expl-edu-title">Share</Card.Title>
              {/* TODO: change this current image for the correct gif from new dashboard, share part */}
              <Card.Img variant="top" src="/share.gif" className="card-expl-edu-img" />
            </div>
            <Card.Body>
              <Card.Text className="card-expl-edu-text">
                Export your charts, graphs, and reports to use them in presentations or share them
                with colleagues and other decision makers.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
        <Row justify="space-between" align="center">
          <Col md="5" className="align-items-center">
            <Card className="card-reports card-reports-bg-color">
              <Card.Body className="card-reports-bg-color">
                <Card.Title className="card-reports-title">Start Creating Reports</Card.Title>
                <Card.Text className="share-bento-text">
                  Explore, filter, and segment teacher and principal survey results.
                </Card.Text>
                <Button
                  variant="primary"
                  href="/reports/shared?shareToken=fd2e8c6a-e4db-44bd-97a1-dceba98bc88e"
                  className="card-reports-button"
                >
                  See Sample Report
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md="7" className="align-items-center">
            <img className="reports-details" src="/group15.svg" />
          </Col>
        </Row>
        <Row justify="space-between" align="center" className="mb-4">
          <Card className="card-method-train">
            <Card.Body className="card-method-train-body">
              <Card.Title className="card-method-train-title">Methodology</Card.Title>
              <Card.Text>
                Describes the data sources, data label definitions, and methods used to process and
                display all of the data in the Bento Survey Explorer.
              </Card.Text>
              <Button
                variant="light"
                size="lg"
                className="card-method-train-button"
                href="https://bentoguide.squarespace.com/survey-methodology"
                target="_blank"
                rel="noopener noreferrer" // to prevent a type of phishing known as tabnabbing.
              >
                Learn More
              </Button>
            </Card.Body>
          </Card>
          <Card className="card-method-train">
            <Card.Body className="card-method-train-body">
              <Card.Title className="card-method-train-title">Training Materials</Card.Title>
              <Card.Text>
                <span>
                  FAQs and Training Materials.
                  <br />
                </span>
                <span>
                  Access videos and support materials for information on how to use Bento.
                </span>
              </Card.Text>
              <Button
                variant="light"
                size="lg"
                className="card-method-train-button"
                href="https://bentoguide.squarespace.com/welcome"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </Button>
            </Card.Body>
          </Card>
        </Row>
        <Row
          noGutters
          className="p-3 text-black d-flex row-background-color align-items-center mb-3"
        >
          <Col md="7" className="align-items-center">
            <div className="share-bento-title">Share Bento with your colleagues!</div>
            <div className="share-bento-text">
              Bento is a FREE tool, available to anyone who wants to use education data to inform
              their work. Help us get Bento into the hands of people who can use it by sending them
              to GetBento.info to learn more and sign up for a free account!
            </div>
          </Col>
          <Col md="5" className="text-white get-info-background get-info-height">
            <a
              className="text-white text-reset text-center share-bento-link"
              href="https://www.getbento.info/"
              target="_blank"
              rel="noopener noreferrer"
            >
              GetBento.info
            </a>
          </Col>
        </Row>
      </Container>
      <Row
        noGutters
        className="footer-copyright p-3 text-black row-background-color footer-bg d-flex align-items-center mt-3 mb-3"
      >
        <Col md="4" className="text-center">
          <a
            href="https://bentotraining.squarespace.com/aboutsurveys"
            className="footer-link-mailto"
            target="_blank"
            rel="noopener noreferrer"
          >
            About Bento
          </a>
        </Col>
        <Col md="4" className="text-center">
          Copyright {new Date().getFullYear()} &copy;
        </Col>
        <Col md="4" className="text-center">
          Contact us at{' '}
          <a href="mailto:bento@mgt.us" className="footer-link-mailto">
            bento@mgt.us
          </a>
        </Col>
      </Row>
      {showTopicVideoModal && (
        <Modal centered show onHide={handleCloseTopicModal} backdrop="static">
          <Modal.Header closeButton>
            <h3>New in Bento: Search by Topic</h3>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <video controls autoPlay width="100%" height="auto" loop>
                  <source
                    src="https://s3.us-east-2.amazonaws.com/admin-ui-uat.bentobento.info/Bento+Topic+Search+-+30+sec+video+FINAL.mp4"
                    type="video/mp4"
                  />
                </video>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" size="xl" onClick={handleCloseTopicModal}>
              Close
            </Button>
            <Button variant="dark" size="xl" onClick={handleGoToTopicSearch}>
              Go to Topic Search
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default HomePage;
