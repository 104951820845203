const themes = [
  {
    index: 0,
    positive_negative: ['#F67F8E', '#F6BDC4', '#A8D3FF', '#426E99'],
    positive: ['#e6f2ff', '#A8D3FF', '#80bfff', '#5e8cba', '#426E99'],
    negative: ['#f3586d', '#F67F8E', '#f08f9a', '#F6BDC4', '#fce9eb'],
    continuous: ['#c6e2cb', '#83cacf', '#3984b6', '#2c5a9c', '#141c58'],
    discrete: [
      '#668cd1',
      '#9ab03e',
      '#726cd7',
      '#60a957',
      '#593586',
      '#cd8e35',
      '#5083de',
      '#96813a',
      '#c17ed4',
      '#45c097',
      '#be549f',
      '#ba4f3f',
    ],
    basic: [
      '#d3e8d7',
      '#c6e2cb',
      '#9ab1ae',
      '#8ba0a5',
      '#8eaac5',
      '#6d7f92',
      '#4f5e7e',
      '#414e75',
      '#1f2461',
      '#0d1852',
      '#000b43',
    ],
    label: 'Gradient',
  },
  {
    index: 1,
    positive_negative: ['#e5a624', '#f9da7c', '#9ec465', '#006d5d'],
    positive: ['#c5dca3', '#9ec465', '#7da63f', '#00ccad', '#006d5d'],
    negative: ['#e5a624', '#ecbd5f', '#f9da7c', '#fae39e', '#fdf1ce'],
    continuous: ['#ffe9a8', '#f9da7c', '#ffc425', '#e5a624', '#d18029'],
    discrete: [
      '#668cd1',
      '#9ab03e',
      '#726cd7',
      '#60a957',
      '#593586',
      '#cd8e35',
      '#5083de',
      '#96813a',
      '#c17ed4',
      '#45c097',
      '#be549f',
      '#ba4f3f',
    ],
    basic: [
      '#fff1c8',
      '#ffe9a8',
      '#ffe393',
      '#f4cf88',
      '#f0c67e',
      '#e8b569',
      '#e0a353',
      '#dd9a49',
      '#d18029',
      '#b86a00',
      '#a35800',
    ],
    label: 'Discrete',
  },
  {
    index: 2,
    positive_negative: ['#ed6a5a', '#f4c289', '#bdb8dd', '#6052a8'],
    positive: ['#efeef7', '#cfcbe6', '#bdb8dd', '#8f87c5', '#6052a8'],
    negative: ['#ed6a5a', '#f2988c', '#f7c1ba', '#f4c289', '#fbe7d0'],
    continuous: ['#C1D7E9', '#A8B5D8', '#9094C8', '#7873B8', '#6052A8'],
    discrete: [
      '#668cd1',
      '#9ab03e',
      '#726cd7',
      '#60a957',
      '#593586',
      '#cd8e35',
      '#5083de',
      '#96813a',
      '#c17ed4',
      '#45c097',
      '#be549f',
      '#ba4f3f',
    ],
    basic: [
      '#d8edff',
      '#c1d7e9',
      '#a9b6d9',
      '#a1abd3',
      '#9195c9',
      '#807ebe',
      '#7873b8',
      '#6052a8',
      '#3a3585',
      '#23236f',
      '#141963',
    ],
    label: 'Blue',
  },
  {
    index: 3,
    positive_negative: ['#7D1858', '#DB6A88', '#ADDBC4', '#3C887E'],
    positive: ['#edf7f2', '#ADDBC4', '#95d0b3', '#5fb9ad', '#3C887E'],
    negative: ['#7D1858', '#ab2178', '#de54ab', '#DB6A88', '#ebadbd'],
    continuous: ['#CEE4E1', '#A9CDC8', '#85B6AF', '#609F96', '#3C887E'],
    discrete: [
      '#668cd1',
      '#9ab03e',
      '#726cd7',
      '#60a957',
      '#593586',
      '#cd8e35',
      '#5083de',
      '#96813a',
      '#c17ed4',
      '#45c097',
      '#be549f',
      '#ba4f3f',
    ],
    basic: [
      '#d8f0ed',
      '#cee4e1',
      '#aacdc8',
      '#9dc5c0',
      '#85b6b0',
      '#6da79f',
      '#619f97',
      '#3c887e',
      '#247269',
      '#015d55',
      '#00524a',
    ],
    label: 'Green',
  },
];
export const SurveyEditThemes = [
  {
    index: 0,
    basic: ['#C6E2CB', '#9AB1AE', '#6D7F92', '#414E75', '#141C58'],
    label: 'Gradient',
  },
  {
    index: 1,
    basic: ['#3D6383', '#8E67AE', '#1FB19D', '#FF8249', '#FFE100'],
    label: 'Discrete',
  },
  {
    index: 2,
    basic: ['#F3586D', '#F6BDC4', '#FEFEE6', '#A8D3FF', '#426E99'],
    label: 'Positive',
  },
];
export default themes;
