/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { QUESTION_FILTER_TYPE, QUESTION_VALUE_TYPE } from '../../../../constants/common';
import { SurveyEditThemes } from '../../../../constants/themes';

import types from '../../../../types';
import './styles.css';

const SurveySTypeQuestionEditForm = ({ handleEditMode }) => {
  const dispatch = useDispatch();
  const [selectedTheme, setSelectedTheme] = useState(0);
  const [questionLabel, setQuestionLabel] = useState('');
  const [questionText, setQuestionText] = useState('');
  const [labels, setLabels] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [filterType, setFilterType] = useState('');
  const { sample, dimensions } = useSelector((state) => state.surveyWizard);

  const onQuestionLabelChange = (e) => {
    setQuestionLabel(e.target.value);
  };
  const onQuestionTextChange = (e) => {
    setQuestionText(e.target.value);
  };

  const onAnswerChange = (e, index) => {
    const answersToUpdate = [...answers];
    answersToUpdate[index].value = e.target.value;
    setAnswers([...answersToUpdate]);
  };

  const onLabelChange = (e, index) => {
    const labelsToUpdate = [...labels];
    labelsToUpdate[index].value = e.target.value;
    setAnswers([...labelsToUpdate]);
  };

  const onSave = () => {
    answers.forEach((an) => {
      dimensions[0].Data.allowedValues[an.id] = an.value;
    });
    labels.forEach((label, index) => {
      dimensions[index].Data.label = label.value;
    });
    sample.Data.label = questionLabel;
    sample.Data.text = questionText;
    sample.Data.filterType = filterType;
    sample.Data.theme = selectedTheme;
    dispatch({ type: types.reducerTypes.SURVEY_SAMPLE_GET_SUCCESS, payload: sample });
    dispatch({
      type: types.reducerTypes.SURVEY_SAMPLE_DIMENSIONS_GET_SUCCESS,
      payload: dimensions,
    });
  };

  const onFilterTypeChange = (e) => {
    setFilterType(e.target.value);
  };

  useEffect(() => {
    if (sample) {
      setQuestionLabel(sample.Data.label);
      setQuestionText(sample.Data.text);
      setFilterType(sample.Data.filterType);
    }
    if (dimensions) {
      if (sample?.Data.valueType !== QUESTION_VALUE_TYPE.MULTIPLE) {
        const { allowedValues } = dimensions[0].Data;
        setAnswers(
          Object.keys(allowedValues)
            .filter((key) => key >= 0)
            .map((key) => ({ id: key, value: allowedValues[key] }))
        );
      } else {
        const labelList = dimensions.map((dimension) => ({
          id: dimension.Id,
          value: dimension.Data.label,
        }));
        setLabels(labelList);
      }
    }
  }, [sample, dimensions]);

  return (
    <Col md="3" className="p-3 survey-sidebar edit-mode">
      <span
        className="d-flex"
        onClick={handleEditMode}
        onKeyDown={handleEditMode}
        role="button"
        tabIndex={0}
      >
        <FontAwesomeIcon size="sm" className="mr-3" icon={faChevronLeft} />
        Back to overview
      </span>
      <Col className="info-edit-form">
        {sample?.Data.valueType === QUESTION_VALUE_TYPE.MULTIPLE && (
          <div>
            <h4>Question Text</h4>
            <Form.Control
              as="textarea"
              className="mt-3"
              value={questionText}
              onChange={onQuestionTextChange}
              style={{ height: '100px' }}
              maxLength={500}
              placeholder="Survey Description"
            />
          </div>
        )}
        {sample?.Data.valueType !== QUESTION_VALUE_TYPE.MULTIPLE && (
          <div>
            <h4>Question Text</h4>
            <Form.Control
              as="textarea"
              className="mt-3"
              value={questionLabel}
              onChange={onQuestionLabelChange}
              style={{ height: '100px' }}
              maxLength={500}
              placeholder="Survey Description"
            />
          </div>
        )}
        {sample?.Data.valueType === QUESTION_VALUE_TYPE.MULTIPLE && (
          <div>
            <h4>Labels</h4>
            {labels.map((label, index) => {
              return (
                <Form.Control
                  className="mt-3"
                  key={label.id}
                  id={label.id}
                  value={label.value}
                  type="text"
                  onChange={(e) => onLabelChange(e, index)}
                />
              );
            })}
          </div>
        )}
        {sample?.Data.valueType !== QUESTION_VALUE_TYPE.MULTIPLE && (
          <div>
            <h4>Answers</h4>
            {answers.map((answer, index) => {
              return (
                <Form.Control
                  className="mt-3"
                  key={answer.id}
                  id={answer.id}
                  value={answer.value}
                  type="text"
                  onChange={(e) => onAnswerChange(e, index)}
                />
              );
            })}
          </div>
        )}
        <div className="border-gray mt-3" />
        <h4 className="mb-3">Filter Category</h4>
        <ListGroup>
          <ListGroup.Item>
            <Form.Check
              type="radio"
              checked={filterType === QUESTION_FILTER_TYPE.NONE}
              id="filter-none"
              label="None"
              value="NONE"
              onClick={onFilterTypeChange}
            />
          </ListGroup.Item>
          {sample?.Data.valueType !== QUESTION_VALUE_TYPE.MULTIPLE && (
            <ListGroup.Item>
              <Form.Check
                checked={filterType === QUESTION_FILTER_TYPE.LOCATION}
                type="radio"
                id="filter-location"
                label="Location"
                value="LOCATION"
                onClick={onFilterTypeChange}
              />
            </ListGroup.Item>
          )}
          <ListGroup.Item>
            <Form.Check
              checked={filterType === QUESTION_FILTER_TYPE.ATTRIBUTE}
              type="radio"
              id="filter-attribute"
              label="Attribute"
              value="ATTRIBUTE"
              onClick={onFilterTypeChange}
            />
          </ListGroup.Item>
          <ListGroup.Item>
            <Form.Check
              checked={filterType === QUESTION_FILTER_TYPE.QUESTION}
              type="radio"
              id="filter-question"
              label="Question"
              value="QUESTION"
              onClick={onFilterTypeChange}
            />
          </ListGroup.Item>
        </ListGroup>
        <h4>Select Color Scheme</h4>
        {SurveyEditThemes.map((theme) => (
          <div key={`theme-${theme.index}`}>
            <div
              className={`colors-rounded ${theme.index === selectedTheme?.index ? 'selected' : ''}`}
              onClick={() => setSelectedTheme(theme)}
              role="button"
              style={{ display: 'flex' }}
            >
              {theme.basic.map((color) => (
                <div
                  role="button"
                  key={`color-${color}`}
                  style={{ backgroundColor: color, flex: 1, height: 20 }}
                />
              ))}
            </div>
            <span>{theme.label}</span>
          </div>
        ))}
      </Col>
      <Button variant="primary" onClick={onSave} className="btn btn-custom dark mt-3">
        Save
      </Button>
    </Col>
  );
};

SurveySTypeQuestionEditForm.propTypes = {
  handleEditMode: PropTypes.func.isRequired,
};

export default SurveySTypeQuestionEditForm;
